import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FileUploader } from "@aws-amplify/ui-react-storage";
import { Mui, MuiStyles } from "@osu/react-ui";
import { remove } from "aws-amplify/storage";

const FORM_STAGING_PREFIX = "form/staging";

function FileUpload(props = {}) {
  const { acceptedFileTypes, autoUpload = true, Description, error, HelperText, id, label, maxFileCount = 1, maxFileSize, onChange, readOnly, required, value, ...rest } = props;
  const alertRef = useRef(null);
  const osuid = useSelector((state) => (state.authentication.user.osuid));
  const [defaultFiles, setDefaultFiles] = useState(null);
  const [defaultFilesPathMap, setDefaultFilesPathMap] = useState({});
  const [fileWarning, setFileWarning] = useState(null);
  const [timestamp] = useState(Number(new Date()));
  const theme = MuiStyles.useTheme();

  const addFile = useCallback((file) => {
    if(!value.includes(file.key)) {
      const newValue = [...value, file.key]
      onChange({ id, value: newValue });
    }
  }, [id, onChange, value]);

  const getFileName = (path) => {
    return path.slice((path.lastIndexOf("/") + 1), path.length);
  };

  const onUploadError = (error) => {
    console.error("Upload File Error: ", error);
    setFileWarning("At least one file failed to upload.");
  };

  const onUploadStart = (file) => {
    const fileName = getFileName(file.key);
    const fileNames = Array.isArray(value) ? value.map((path) => (getFileName(path))) : [];
    if(fileNames.includes(fileName)) {
      setFileWarning("A file with the same name has already been uploaded. Saving the new file will overwrite the current file of the same name.");
    }
  };

  const processFile = async ({ file }) => {
    return { contentType: file.type, file, key: file.name };
  };

  const removeFile = async (file) => {
    // For default files the file key is the file name so use the map to get the path.
    // For added files the file key is the path.
    const path = (defaultFilesPathMap[file.key] ?? file.key);
    let index = value.indexOf(path);
    if(index > -1) {
      const newValue = [...value];
      newValue.splice(index, 1);
      onChange({ id, value: newValue });
      if(defaultFilesPathMap[file.key]) delete defaultFilesPathMap[file.key]; // remove the entry from the map once the default file is removed
    }
    // remove staging files from the bucket but leave form files in case the form is not saved
    // form files will be cleaned up on the backend on save/submit
    if(path.startsWith(FORM_STAGING_PREFIX)) {
      try {
        await remove({ path });
      } catch(error) {
        console.error("Remove File Error: ", error);
      }
    }
  };

  // when files are available, update default files and state (if necessary)
  useEffect(() => {
    if(Array.isArray(value)) {
      const formFiles = [];
      const stagingFiles = [];
      for(const path of value) {
        if(path.startsWith(FORM_STAGING_PREFIX)) {
          stagingFiles.push(path);
        } else {
          formFiles.push(path);
        }
      }

      const filesPathMap = {};
      const files = formFiles.map((path) => {
        addFile({ key: path }); // update state
        const fileName = getFileName(path);
        // to match the file display of an added file, the key must be the file name
        // maintain a map of file name to path to be able to get the path from the file name for default file removal
        filesPathMap[fileName] = path;
        return { key: fileName };
      });
      if(files.length > 0 && stagingFiles.length === 0) { // do not set default files unless available (the component should only re-render when it has default files otherwise added files may be lost)
        setDefaultFiles(files);
        setDefaultFilesPathMap(filesPathMap);
        setFileWarning(null);
      }
    }
  }, [addFile, value]);

  // when there is a file warning, set focus on the alert
  useEffect(() => {
    if(!!fileWarning && alertRef.current) alertRef.current.focus();
  }, [alertRef, fileWarning]);

  const fileUploaderProps = {
    acceptedFileTypes: acceptedFileTypes,
    autoUpload: true,
    components: {
      FilePicker({ onClick }) {
        return (<Mui.Button color="primary" onClick={onClick} variant="contained">Browse Files</Mui.Button>);
      }
    },
    maxFileCount: maxFileCount,
    maxFileSize: maxFileSize,
    onFileRemove: removeFile,
    onUploadError: onUploadError,
    onUploadStart: onUploadStart,
    onUploadSuccess: addFile,
    path: `${FORM_STAGING_PREFIX}/${osuid}/${timestamp}/`,
    processFile,
    showThumbnails: false
  };
  /* Note: FileUploader currently only displays defaultFiles when they are available on first render.
     In order to handle this a new component is rendered once defaultFiles are available. */
  return (
    <Mui.FormControl required={required} {...rest}>
      <Mui.FormGroup id={id}>
        {label && <Mui.FormLabel sx={{ "&": { color: theme.palette.text.primary } }}>{label}</Mui.FormLabel>}
        {Description && <Description />}
        {!!fileWarning && 
          <Mui.Alert ref={alertRef} severity="warning" tabIndex={-1} onClose={() => (setFileWarning(null))}>
            <Mui.AlertTitle>Review Files</Mui.AlertTitle>
            {fileWarning}
          </Mui.Alert>
        }
        {!defaultFiles && <FileUploader {...fileUploaderProps} />}
        {!!defaultFiles && <FileUploader {...fileUploaderProps} defaultFiles={defaultFiles} />}
        {HelperText && <HelperText />}
      </Mui.FormGroup>
    </Mui.FormControl>
  );
}

export default FileUpload;