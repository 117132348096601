import { useCallback } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { compact, intersection } from "lodash";
import { getFormByType } from "../paths";
import { APPROVAL_STATUS, formTypeIdentifiers, formTypeIdentifiersUnderGrad } from "../../util/constants";

export default function useFormSubmission(props = {}) {
    const navigate = useNavigate();
    const user = useSelector((state) => (state?.authentication?.user ?? {}));
    const { applicationRoles, gradPlanAdminPlans, isEnrollmentAdmin, isFacultyApprover, isGradPlanAdmin, isGraduationAdmin, isUnderGradStudent } = user;
    const osuid = (props?.osuid ?? user.osuid);

    const canUserAccessFormType = useCallback((formType) => {
        // undergraduate students only have access to specific forms
        if(applicationRoles.length === 1 && isUnderGradStudent === true) {
            return (formTypeIdentifiersUnderGrad.includes(formType));
        }
        return true;
    }, [applicationRoles, isUnderGradStudent]);

    const canUserActOnForm = useCallback((form = {}) => {
        const formStatus = form?.status;
        const formType = form?.formType;
        const isGradFacultyNominationForm = (formType === formTypeIdentifiers.GRAD_FACULTY_NOMINATION);
        if(formStatus === APPROVAL_STATUS.DRAFT) {
            if(isGradFacultyNominationForm === true) {
                return (
                    isFacultyApprover === true || 
                    (isGradPlanAdmin === true && (gradPlanAdminPlans.includes(form.academicPlan) || form.requesterId === osuid))
                );
            } else {
                return (compact([form?.requesterId, form.studentid]).includes(osuid));  
            }
        }
        if(formStatus === APPROVAL_STATUS.PENDING) {
            if(isGradFacultyNominationForm === true) {
                return (isFacultyApprover === true);
            } else {
                const { getAdminRoles } = getFormByType(formType);
                const adminRoles = (getAdminRoles ? getAdminRoles(form) : []);
                return intersection(applicationRoles, adminRoles).length > 0
            }
        }
        return false;
    }, [applicationRoles, gradPlanAdminPlans, isFacultyApprover, isGradPlanAdmin, osuid]);

    const navigateToFormLandingPage = useCallback((formTypeFriendly, errorMessage = null) => {
        const options = {};
        if(errorMessage) options.state = { errorMessage };
        navigate(`/form/${formTypeFriendly}`, options);
    }, [navigate]);

    const isUserCreatorOfForm = useCallback((form) => {
        return (form.createdBy === osuid);
    }, [osuid]);

    const isUserStudentOnForm = useCallback((form) => {
        return (form.studentid === osuid);
    }, [osuid]);

    const validateEditPermitted = useCallback((form) => {
        let error = null;
        if((isEnrollmentAdmin || isGraduationAdmin)) {
            if([APPROVAL_STATUS.CANCELLED, APPROVAL_STATUS.COMPLETED].includes(form.status)) {
                error = "This form cannot be edited once it has been completed or cancelled.";
            }
        } else {
            if((!isUserStudentOnForm(form) && !isUserCreatorOfForm(form)) || form.status !== APPROVAL_STATUS.DRAFT) {
                error = "This form cannot be edited once it has been submitted.";
            }
        }
        return error;
    }, [isUserCreatorOfForm, isUserStudentOnForm, isEnrollmentAdmin, isGraduationAdmin]);

    return {
        canUserAccessFormType,
        canUserActOnForm,
        navigateToFormLandingPage,
        validateEditPermitted
    };
}