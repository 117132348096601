import {
  ERROR_IN_FORM,
  EXISTING_FORMS,
  getSections,
  INDIVIDUAL_FORM,
  SUBMIT_TEMPLATE_FORM
} from "../actions-index";
import { generateAction } from "../redux-helpers";
import * as Queries from "../graphql/queries";
import { generateClient } from "aws-amplify/api";
import { getFormByType } from "./paths";
import { transformFormDataToPayload } from "./transform";
import { createFormTypeBasedFormRequest } from "../graphql/custom";
import { ERROR_TYPE, FORM_TYPE_HTML_FRIENDLY } from "../util/constants";
import { forOwn, isEmpty } from "lodash";

const API = generateClient();

export function submitTemplateForm() {
  return (dispatch) => {
    dispatch(
      generateAction(SUBMIT_TEMPLATE_FORM, {
        completed: true,
      })
    );
  };
}

function buildFormContext(values) {
  const { formType, formData, user, delegate, creator } = values;
  const formDefinition = (getFormByType(formType) ?? {});
  const { isStudentForm = false, contextFields = {}, useUserAcademicPlan = false } = formDefinition;
  if(isStudentForm === true) {
    if(delegate) {
      return { studentid: creator };
    } else {
      const context = { studentid: user.osuid, careerNumber: user?.careerNumber, pcdIndicator: user?.pcdIndicator };
      if(useUserAcademicPlan === true) context.academicPlan = user?.academicPlan;
      return context;
    }
  } else {
    const getContextFieldValue = (contextField) => {
      let contextFieldValue = null;
      const contextFieldTokens = contextField.split(".");
      if(contextFieldTokens.length > 1) {
          let value = formData[contextFieldTokens[0]];
          if(value) {
            for(let i = 1; i < contextFieldTokens.length; i++) {
              value = value[contextFieldTokens[i]];
              if(!value) break;
            }
          }
          if(value) contextFieldValue = value;
      } else {
        contextFieldValue = formData[contextFieldTokens[0]];
      }
      return contextFieldValue;
    };
    const formContext = { requesterId: (delegate ? creator : user.osuid) };
    forOwn(contextFields, (value, key) => {
      formContext[key] = getContextFieldValue(value);
    });
    if (Object.prototype.hasOwnProperty.call(formContext, "studentid")) {
      if (formContext["studentid"] === null || formContext["studentid"] === undefined ) {
        // already exists in formContext but is null or undefined
        formContext.studentid = formData.osuid;
      }
    } else {
      // is not in formContext
      formContext.studentid = formData.osuid;
    }
    return formContext;
  }
}

export function onFormSave(values) {
  const { id, formType, formData, osuid = null, stepState, user, delegate, creator } = values;
  const { body } = transformFormDataToPayload(formData, formType, { validateDeadline: !delegate })
  const formRequest = createFormTypeBasedFormRequest(formType, false)
  const formContext = buildFormContext({ formType, formData: body, user, delegate, creator });
  return async (dispatch) => {
    dispatch(
      generateAction(SUBMIT_TEMPLATE_FORM, {
        status: "loading"
      })
    );
    try {
      const variables = {
        input: {
          action: 'SAVE',
          formType,
          id,
          ...body,
          ...formContext
        }
      };
      const response = await API.graphql({ query: formRequest, variables });
      if(response?.data?.updateForm?.form?.id) {
        let success = {
          status: response?.data?.updateForm?.status,
          id: response?.data?.updateForm?.form?.id,
          osuid
        }
        await dispatch(generateAction(INDIVIDUAL_FORM, response.data.updateForm.form)); // update form state
        await dispatch(getSections({ id: response.data.updateForm.form.id, osuid, formType })); // update editable and formatted form state
        dispatch(generateAction(SUBMIT_TEMPLATE_FORM, success)); // update submission state
      } else {
        throw new Error("Unexpected response from update form")
      }
    } catch (error) {
      console.error(error)
      let errorMessage = null;
      if(error?.errors?.[0]?.errorType === ERROR_TYPE.VALIDATION) {
        errorMessage = error.errors[0].message;
      }
      dispatch(
        generateAction(SUBMIT_TEMPLATE_FORM, {
          errorMessage,
          status: "error",
          stepState // return existing stepState so that it can be re-initialized
        })
      );
      
    }
  };
}

export function onFormSubmit(values) {
  const { id, formType, formData, osuid = null, stepState, user } = values
  const { errors, componentState, body } = transformFormDataToPayload(formData, formType)
  const formRequest = createFormTypeBasedFormRequest(formType, false)
  const formContext = buildFormContext({ formType, formData: body, user });
  //To test the backend valiadtions remove the if check for errors
  if(errors?.length) {
    return (dispatch) => {
      dispatch(
        generateAction(ERROR_IN_FORM, {
          [id ?? FORM_TYPE_HTML_FRIENDLY[formType]]: {
            state: componentState,
            errors
          }
        })
      );
    };
  }
  return async (dispatch) => {
    dispatch(
      generateAction(SUBMIT_TEMPLATE_FORM, {
        status: "loading"
      })
    );
    try {
      const response = await API.graphql({ query: formRequest, variables: {
        input: {
          action: 'SUBMIT',
          formType,
          id,
          ...body,
          ...formContext
        }
      } });
      
      if(response?.data?.updateForm?.form?.id) {
        await dispatch(generateAction(INDIVIDUAL_FORM, response.data.updateForm.form)); // update form state
        await dispatch(getSections({ id: response.data.updateForm.form.id, osuid, formType })); // update editable and formatted form state
        dispatch(
          generateAction(SUBMIT_TEMPLATE_FORM, {
            status: response?.data?.updateForm?.status,
            id: response?.data?.updateForm?.form?.id,
            completed: true,
            osuid
          })
        ); // update submission state
      } else {
        throw new Error("Unexpected response from update form")
      }
    } catch (error) {
      console.error(error)
      let errorMessage = null;
      if(error?.errors?.[0]?.errorType === ERROR_TYPE.VALIDATION) {
        errorMessage = error.errors[0].message;
      }
      dispatch(
        generateAction(SUBMIT_TEMPLATE_FORM, {
          errorMessage,
          status: "error",
          stepState // return existing stepState so that it can be re-initialized
        })
      );
    }
  };
}