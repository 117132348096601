import { BOOL } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "form",
    questions: [
      {
        id: "completion-term",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        title: "Completion Term",
        dataField: "termCode",
        required: true,
        filter: {
          showFromPresent: 2
        }
      },
      {
        id: "end-of-semester-option",
        summary: "The end of semester option means that you will not be graduating in the current semester. The EOS option is to be used only if all degree requirements are to be completed by the posted end of semester deadline with the degree being conferred at the commencement ceremony of the following semester. Do not utilize this option if you are trying to graduate using the regular semester/summer term graduation deadlines.",
        type: "checkbox",
        dataField: "endOfSemesterOption",
        defaultValue: BOOL.NO,
        choices: [
          {
            id: "end-of-semester-option-yes",
            title: "End of Semester Option",
            value: BOOL.YES
          }
        ]
      },
      {
        id: "certificate-plan",
        type: inputMappings.dropDown,
        dataField: "academicPlan",
        subtype: dropdownSubtypes.certicatePlans,
        title: "Certificate Plan",
        required: true
      },
      {
        id: "courses-input-group",
        title: "Courses",
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: "courses",
        required: true,
        questions: [
          {
            id: "courses-ig-department",
            type: inputMappings.textInput,
            title: "Department",
            summary: null,
            required: true,
            dataField: "department",
            gridProps: {
              xs: 12,
              sm: 8,
              md: 4
            }
          },
          {
            id: "courses-ig-number",
            type: inputMappings.textInput,
            title: "Course Number",
            dataField: "courseNumber",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 4,
              md: 2
            }
          },
          {
            id: "courses-ig-title",
            type: inputMappings.textInput,
            title: "Course Title",
            dataField: "courseTitle",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 8,
              md: 4
            }
          },
          {
            id: "courses-ig-credit-hours",
            type: inputMappings.numberInput,
            title: "Credit Hours",
            dataField: "creditHours",
            summary: null,
            required: true,
            gridProps: {
              xs: 12,
              sm: 4,
              md: 2
            },
            inputProps: {
              min: 0
            }
          }
        ]
      }
    ]
  }
];

export const filters = [
  {
    id: "term",
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.term,
    title: "Completion Term",
    required: true,
    filter: {
      showFromPresent: 2
    }
  }
]

export const additionalFilters = [
  {
    title: "Completion Term",
    id: "completionTerm",
    type: inputMappings.dropDown,
    subtype: dropdownSubtypes.term,
    dataField: 'completionTerm',
    filter: {
      showFromPresent: 2
    }
  }
];