import { Mui } from "@osu/react-ui";
import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "form-information",
    questions: [
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        branch: "graduation-information-plan",
        dataField: "gradLevel",
        subtype: dropdownSubtypes.graduationLevel,
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        dataField: "academicPlan",
        filter: [
          {
            field: "graduation-information-level",
            match: "gradLevel",
            operation: "includes"
          },
        ],
        branch: "advisor-info",
        results: [{
          label: 'Degree',
          dataField: "gradDegree",
          field: "degree"
        }],
        dependencyHelperText: "Graduation Level must be selected before choosing a Graduation Plan",
        dependencies: ["graduation-information-level"],
      },
      {
        id: "minor-or-gis",
        type: "radio",
        title: "What type of specialization do you require?",
        required: true,
        dataField: "concentrationType",
        choices: [
          {
            id: "minor-options",
            title: "Minor",
            value: "MINOR",
            branch: "minor-designations-options",
          },
          {
            id: "minor-designations-options",
            title: "Designation Name",
            type: inputMappings.dropDown,
            subtype: dropdownSubtypes.designationMinor,
            dataField: "designation",
            dependencies: ["minor-or-gis"],
            required: true
          },
          {
            id: "interdisciplinary-specializations",
            title: "Interdisciplinary Specializations",
            value: "INTERDISC_SPECIALIZATION",
            branch: "gis-designations-options",
          },
          {
            id: "gis-designations-options",
            title: "Designation Name",
            type: inputMappings.dropDown,
            subtype: dropdownSubtypes.designationGIS,
            dataField: "designation",
            dependencies: ["minor-or-gis"],
            required: true
          },
        ],
      },
      {
        id: "courses-input-group",
        title: "Courses",
        required: true,
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 10,
        dataField: "courses",
        questions: [
          {
            id: "courses-ig-department",
            type: "textInput",
            title: "Department",
            dataField: "department",
            summary: null,
            required: true,
          },
          {
            id: "courses-ig-number",
            type: "textInput",
            title: "Course Number",
            dataField: "courseNumber",
            summary: null,
            required: true,
          },
          {
            id: "courses-ig-title",
            type: "textInput",
            title: "Course Title",
            dataField: "courseTitle",
            summary: null,
            required: true,
          },
          {
            id: "courses-ig-credit-hours",
            type: "textInput",
            title: "Credit Hours",
            dataField: "creditHours",
            summary: null,
            required: true,
          },
        ],
      },
      {
        id: "advisor-info",
        type: inputMappings.dropDown,
        title: "Advisor",
        dataField: "advisor",
        subtype: dropdownSubtypes.advisor,
        dependencies: ["graduation-information-plan"], 
        dependencyHelperText: "Graduation Plan must be selected before choosing an Advisor",
        required: false
      }
    ]
  }
];

export const additionalFilters = [ {
  id: "created-date-after",
  title: "Date Created After",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "created-date-before",
  title: "Date Created Before",
  type: inputMappings.date,
  startTime: false,
  endTime: false,
},
{
  id: "candidacy-type",
  title: "Candidacy Type",
  required: false,
  type: inputMappings.dropDown,
  subtype: dropdownSubtypes.candidacyType
}];