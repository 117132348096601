export const ACADEMIC_PROGRAMS = 'ACADEMIC_PROGRAMS';
export const ACADEMIC_UNITS = 'ACADEMIC_UNITS';
export const ADVISORS = 'ADVISORS';
export const CERTIFICATE_PLANS = 'CERTIFICATE_PLANS';
export const CO_ADVISORS = 'CO-ADVISORS';
export const FACULTY_MEMBERS = 'FACULTY_MEMBERS';
export const GRADUATE_CONTACT_AFFILIATION_COMMENT = 'GRADUATE_CONTACT_AFFILIATION_COMMENT';
export const GRADUATE_CONTACT_AFFILIATION_COMMENT_RESET = 'GRADUATE_CONTACT_AFFILIATION_COMMENT_RESET';
export const GRADUATE_CONTACT_AFFILIATION_COMMENTS = 'GRADUATE_CONTACT_AFFILIATION_COMMENTS';
export const GRADUATE_CONTACT_AFFILIATION_COMMENTS_RESET = 'GRADUATE_CONTACT_AFFILIATION_COMMENTS_RESET';
export const GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY = 'GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY';
export const GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY_RESET = 'GRADUATE_CONTACT_AFFILIATION_WORKFLOW_HISTORY_RESET';
export const GRADUATE_CONTACT_APPROVE = 'GRADUATE_CONTACT_APPROVE';
export const GRADUATE_CONTACT_DENY = 'GRADUATE_CONTACT_DENY';
export const GRADUATE_CONTACT_SEARCH = 'GRADUATE_CONTACT_SEARCH';
export const GRADUATE_CONTACT_SUBMIT = 'GRADUATE_CONTACT_SUBMIT';
export const GRADUATE_CONTACTS = 'GRADUATE_CONTACTS';
export const GRADUATE_CONTACTS_PENDING = 'GRADUATE_CONTACTS_PENDING';
export const GRADUATE_FACULTY_APPOINTMENT_COMMENT = 'GRADUATE_FACULTY_APPOINTMENT_COMMENT';
export const GRADUATE_FACULTY_APPOINTMENT_COMMENT_RESET = 'GRADUATE_FACULTY_APPOINTMENT_COMMENT_RESET';
export const GRADUATE_FACULTY_APPOINTMENT_COMMENT_STATUS = 'GRADUATE_FACULTY_APPOINTMENT_COMMENT_STATUS';
export const GRADUATE_FACULTY_APPOINTMENT_COMMENTS = 'GRADUATE_FACULTY_APPOINTMENT_COMMENTS';
export const GRADUATE_FACULTY_APPOINTMENT_COMMENTS_RESET = 'GRADUATE_FACULTY_APPOINTMENT_COMMENTS_RESET';
export const GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY = 'GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY';
export const GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY_RESET = 'GRADUATE_FACULTY_APPOINTMENT_WORKFLOW_HISTORY_RESET';
export const GRADUATE_FACULTY = 'GRADUATE_FACULTY';
export const GRADUATE_FACULTY_PENDING = 'GRADUATE_FACULTY_PENDING';
export const GRADUATE_FACULTY_REPRESENTATIVES = 'GRADUATE_FACULTY_REPRESENTATIVES';
export const GRADUATE_FACULTY_SEARCH = 'GRADUATE_FACULTY_SEARCH';
export const GRADUATE_FACULTY_STATUS = 'GRADUATE_FACULTY_STATUS';
export const GRADUATE_FACULTY_SUBMIT = 'GRADUATE_FACULTY_SUBMIT';
export const GRADUATE_FACULTY_APPROVE = 'GRADUATE_FACULTY_APPROVE';
export const GRADUATE_FACULTY_DENY = 'GRADUATE_FACULTY_DENY';
export const GRADUATE_PROGRAM_MAP = 'GRADUATE_PROGRAM_MAP';
export const INTERDISCIPLINARY_SPECIALIZATIONS = 'INTERDISCIPLINARY_SPECIALIZATIONS';
export const MINORS = 'MINORS';
export const SEARCH_PROGRAM_MAP = 'SEARCH_PROGRAM_MAP';
export const SPECIALIZATIONS = 'SPECIALIZATIONS';
export const DROPDOWNS = 'DROPDOWNS';
export const RESET_DROPDOWNS = 'RESET_DROPDOWNS';
export const PERSON = 'PERSON';
export const PROPOSED_ADVISORS = 'PROPOSED_ADVISORS';
export const JOB_CODES = 'JOB_CODES'
export const SET_USER = 'SET_USER';
export const USER_IS_LOADING = 'USER_IS_LOADING';
export const USER_STATUS = 'USER_STATUS';
export const FETCH_USER = 'FETCH_USER';
export const INITIALIZE_TEMPLATE_FORM = 'INITIALIZE_TEMPLATE_FORM';
export const RESET_TEMPLATE_FORM = 'RESET_TEMPLATE_FORM';
export const SUBMIT_TEMPLATE_FORM = 'SUBMIT_TEMPLATE_FORM';
export const FORMS_SEARCH = 'FORMS_SEARCH';
export const GRAD_RECORDS_SEARCH = 'GRAD_RECORDS_SEARCH';
export const ERROR_IN_FORM = 'ERROR_IN_FORM';
export const INDIVIDUAL_FORM = 'INDIVIDUAL_FORM';
export const INDIVIDUAL_FORM_FORMATTED = 'INDIVIDUAL_FORM_FORMATTED';
export const INDIVIDUAL_FORM_EDITABLE = 'INDIVIDUAL_FORM_EDITABLE';
export const INDIVIDUAL_FORM_HISTORY = 'INDIVIDUAL_FORM_HISTORY';
export const EXISTING_FORMS = 'EXISTING_FORMS';
export const LANDING_FORMS = 'LANDING_FORMS';
export const DASH_ALERTS = 'DASH_ALERTS';
export const DASH_ACTIVE_FORMS = 'DASH_ACTIVE_FORMS'
export const DASH_COMPLETED_FORMS = 'DASH_COMPLETED_FORMS'
export const DEGREES = 'DEGREES';
export const FORM_ACTION = 'FORM_ACTION';
export const TASK_ACTION = 'TASK_ACTION';
export const TASKS = 'TASKS';
export const TERMS = 'TERMS';
export const FACULTY_SEARCH = 'FACULTY_SEARCH';
export const STUDENT_INFORMATION = 'STUDENT_INFORMATION';
export const FORM_COMMENT = 'FORM_COMMENT';
export const FORM_COMMENT_RESET = 'FORM_COMMENT_RESET';
export const FORM_COMMENTS = 'FORM_COMMENTS';
export const FORM_COMMENTS_RESET = 'FORM_COMMENTS_RESET';


/* Export each action file below */
export * from './Authentication/actions';
export * from './Common/actions';
export * from './Maintenance/actions';
export * from './Forms/actions';
export * from './Search/actions';
export * from './Form/actions';
export * from './Dashboard/actions';
export * from './Review/actions';