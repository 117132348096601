import React from "react";
import PropTypes from "prop-types";
import { Mui, MuiStyles } from "@osu/react-ui";
import { capitalize, values } from "lodash";
import { APPROVAL_STATUS } from "../../util/constants";

function ApprovalStatus(props = {}) {
    const { value } = props;
    const theme = MuiStyles.useTheme();
    if(!value || !values(APPROVAL_STATUS).includes(value)) return null;

    let backgroundColor;
    switch(value) {
        case APPROVAL_STATUS.APPROVED:
        case APPROVAL_STATUS.SATISFACTORY:
            backgroundColor = theme.palette.success.main;
            break;
        case APPROVAL_STATUS.CANCELLED:
            backgroundColor = theme.palette.primary.light;
            break;
        case APPROVAL_STATUS.COMPLETED:
            backgroundColor = theme.palette.primary.main;
            break;
        case APPROVAL_STATUS.DENIED:
        case APPROVAL_STATUS.UNSATISFACTORY:
            backgroundColor = theme.palette.error.main;
            break;
        case APPROVAL_STATUS.PENDING:
            backgroundColor = theme.palette.warning.main;
            break;
        default:
            backgroundColor = theme.palette.secondary.main;
    }
    theme.approvalStatusColor = backgroundColor; // used for testing

    return (
        <Mui.Box sx={{
            alignItems: "center",
            backgroundColor,
            borderRadius: "0.938rem",
            color: "white",
            display: "flex",
            height: "2rem",
            padding: "1rem",
            width: "fit-content"
        }}>
            {capitalize(value)}
        </Mui.Box>
    );
}

ApprovalStatus.propTypes = {
    value: PropTypes.oneOf(values(APPROVAL_STATUS)).isRequired
}

export default ApprovalStatus;