import React, { Fragment, useEffect, useRef, useState } from "react";
import { Mui } from "@osu/react-ui";
import PropTypes from "prop-types";
import { ACTION_STATUS } from "../../util/constants";
import { formatDate } from "../../util/functions";
import { unix } from "moment";

function Comments(props = {
    createFormComment: () => {},
    createStatus: "",
    getFormComments: () => {},
    resetComment: () => {},
    status: "",
    statusMessage: "",
    sx: {},
    sxContentItem: {},
    emplid: "",
    formType: "",
    formId: ""
}) {
    const { createFormComment, getFormComments, authStatus, formCommentMap = {}, formCommentsMap = {}, user, resetAllFormComment, sx, sxContentItem, emplid, formType, formId } = props;
    const { status: createStatus, statusMessage } = formCommentMap
    const { status: commentsStatus, data: comments = [] } = formCommentsMap
    const isCreating = (createStatus === ACTION_STATUS.LOADING);
    const isLoading = (commentsStatus === ACTION_STATUS.LOADING);
    const hasError = (commentsStatus === ACTION_STATUS.ERROR);
    const alerts = useRef(null);
    const [commentText, setCommentText] = useState("");
    const [isCommentPrivate, setIsCommentPrivate] = useState();

    const CreateCommentErrorAlert = () => {
        return (
            ([ACTION_STATUS.ERROR, ACTION_STATUS.FORBIDDEN].includes(createStatus)) ?
            (<Mui.Alert severity="error" onClose={() => resetAllFormComment()}>
                {statusMessage ?? "There was an error saving the comment."}
            </Mui.Alert>) :
            null
        );
    };

    const handleCreateComment = async () => {
        let isPrivate = isCommentPrivate;
        if(isPrivate === undefined) isPrivate = false
        createFormComment( emplid, formId, formType, isPrivate, commentText)
    }

    const handleCommentTypeChange = (event) => {
        if(event.target.value === "private") setIsCommentPrivate(true);
        if(event.target.value === "public") setIsCommentPrivate(false);
    }

    const isSaveDisabled = ((user?.isGraduationAdmin || user?.isEnrollmentAdmin) &&  (isCommentPrivate === undefined)) || (commentText === "")

    // when the create status changes
    useEffect(() => {
        // on success, clear the textarea
        if(createStatus === ACTION_STATUS.SUCCESS) {
            setCommentText("");
            setIsCommentPrivate(undefined)
        }
        // on error, set focus on alerts
        if([ACTION_STATUS.ERROR, ACTION_STATUS.FORBIDDEN].includes(createStatus) && alerts.current) alerts.current.focus();
    }, [createStatus, setCommentText]);

    return (
        <Mui.Box sx={sx}>
            {isLoading &&
                <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={sxContentItem}>
                    <Mui.CircularProgress aria-describedby="loadingComments" />
                    <Mui.Typography id="LoadingComments" variant="body1" aria-live="assertive">Loading Comments, please wait...</Mui.Typography>
                </Mui.Box>
            }
            {hasError &&
                <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={sxContentItem}>
                    <Mui.Typography variant="body1">There was an error loading comments</Mui.Typography>
                    <Mui.Button variant="contained" color="primary" aria-label="Reload Comments" onClick={() => getFormComments()}>Reload</Mui.Button>
                </Mui.Box>
            }
            {!isLoading &&
                <Fragment>
                    {comments?.map((comment, index) => {
                        if(!comment?.private || (user?.osuid !== comment?.emplid)){
                            return (
                                <Mui.Box key={index} sx={{...sxContentItem, marginBottom: "0.5rem"}}>
                                    <Mui.Box sx={{ display: "inline", marginRight: "0.5rem"}}>
                                        <Mui.Typography component="span" variant="h6">{comment.submittedByName}</Mui.Typography>
                                    </Mui.Box>
                                    <Mui.Box sx={{ display: "inline", marginRight: "0.5rem"}}>
                                        <Mui.Typography component="span" variant="h6">{comment.submittedBy}</Mui.Typography>
                                    </Mui.Box>
                                    <Mui.Box sx={{ display: "inline" }}>
                                        <Mui.Typography component="span" variant="body2" color="secondary">{formatDate(comment.submittedDate)}</Mui.Typography>
                                    </Mui.Box>
                                    {comment?.private && (<Mui.Box sx={{ display: "inline" }}>
                                        <Mui.Typography component="span" variant="body3" color="primary"> (private) </Mui.Typography>
                                    </Mui.Box>) }
                                    <Mui.Box sx={{ display: "block" }}>
                                        <Mui.Typography component="span" variant="body2">{comment.text}</Mui.Typography>
                                    </Mui.Box>
                                </Mui.Box>
                            );
                        } else {
                            return <></>
                        }
                    })}
                    {isCreating &&
                        <Mui.Box display="flex" flexDirection="column" justifyContent="center" alignItems="center" sx={sxContentItem}>
                            <Mui.CircularProgress aria-describedby="savingComment" />
                            <Mui.Typography id="savingComment" variant="body1" aria-live="assertive">Saving Comment, please wait...</Mui.Typography>
                        </Mui.Box>
                    }
                    <div ref={alerts} tabIndex="-1" sx={sxContentItem}>
                        <CreateCommentErrorAlert />
                    </div>
                    <Mui.Box sx={{...sxContentItem, ...(isCreating ? { display: "none" } : {})}}>
                        <br/>
                        <Mui.TextareaAutosize aria-labelledby="addCommentsLabel" aria-describedby="addCommentsHelperText" maxLength={1500} minRows={6}
                            style={{ resize: "vertical", width: "100%" }} value={commentText} onChange={e => setCommentText(e.target.value)} placeholder="1500 Character Limit" />
                        {/* <Mui.FormHelperText id="addCommentsHelperText">Information in this request is viewable and subject to disclosure under the Ohio public records statute and rules of discovery.</Mui.FormHelperText> */}
                        {(user?.isGraduationAdmin || user?.isEnrollmentAdmin) ? (<Mui.Box sx={{marginLeft: "-0.6rem"}}>
                            <Mui.Radio
                                checked={isCommentPrivate === false}
                                onChange={handleCommentTypeChange}
                                value="public"
                                name="comment-type"
                                inputProps={{ 'aria-label': 'Public Comment' }}
                            /> Public Comment
                            <Mui.Radio
                                checked={isCommentPrivate === true}
                                onChange={handleCommentTypeChange}
                                value="private"
                                name="comment-type"
                                inputProps={{ 'aria-label': 'Work Note' }}
                            /> Work Note
                        </Mui.Box>) : <></>}
                        <Mui.Button aria-label="Save Comment" title="Save Comment" variant="contained" color="primary"
                            disabled={isSaveDisabled} onClick={handleCreateComment}>
                            Save
                        </Mui.Button>
                    </Mui.Box>
                </Fragment>
            }
        </Mui.Box>
    );
}

Comments.propTypes = {
    createFormComment: PropTypes.func,
    createStatus: PropTypes.string,
    data: PropTypes.array,
    getFormComments: PropTypes.func,
    resetComment: PropTypes.func,
    status: PropTypes.string,
    statusMessage: PropTypes.string,
    sx: PropTypes.object,
    sxContentItem: PropTypes.object
}

export default Comments;