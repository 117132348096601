import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import { Mui } from "@osu/react-ui";
import { MAIN_ID } from "../../util/constants";

export default function PageNotFound() {
    useEffect(() => {
        document.getElementById(MAIN_ID).focus();
    },[]);  
    return (
        <Mui.Stack direction="column" spacing={2}>
            <Mui.Typography variant="h2">Page Not Found</Mui.Typography>
            <Mui.Typography>The page you requested could not be found.</Mui.Typography>
            <Mui.Box>
                <Mui.Button component={Link} to="/" sx={{ textTransform: "none" }}>Go Home</Mui.Button>
            </Mui.Box>
        </Mui.Stack>
    );
}