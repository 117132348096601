import { INDIVIDUAL_FORM, 
  INDIVIDUAL_FORM_EDITABLE,
  INDIVIDUAL_FORM_FORMATTED,
  INDIVIDUAL_FORM_HISTORY,
  LANDING_FORMS,
  SUBMIT_TEMPLATE_FORM,
  FORM_ACTION,
  TASK_ACTION,
  FORM_COMMENT_RESET,
  FORM_COMMENT,
  FORM_COMMENTS_RESET,
  FORM_COMMENTS 
} from "../actions-index";
import { ACTION_STATUS } from "../util/constants.js";


export function form(state = {}, action = {}) {
  const { type, payload = {} } = action;
  
  const id = payload?.id
  const constructFieldStructure = (field = "") => {
    if(!id) {
      return state
    }
    let obj = {
      ...state,
      [id]: {
        ...state?.[field]?.[id] ?? {},
        ...payload
      }
    }

    if(Object.keys(payload?.[field] ?? {})) {
      obj[id].status = payload?.status ?? 'success'
    }
    return {
      ...state,
      [field]: obj
    }
  }

  switch (type) {
    case INDIVIDUAL_FORM:
      return id ? {
        ...state,
          [id]: {
            ...payload
          }
        } : state
    case INDIVIDUAL_FORM_FORMATTED: {
      return constructFieldStructure('formatted')
    }
    case INDIVIDUAL_FORM_EDITABLE: {
      return constructFieldStructure('edit')
    }
    case INDIVIDUAL_FORM_HISTORY: {
      return constructFieldStructure('history')
    }
    case SUBMIT_TEMPLATE_FORM:
      return {
        ...state,
        submission: payload
      }
    default:
      return state;
  }
}

export function landingForms(state = {}, action = {}) {
  return (action.type === LANDING_FORMS ? action.payload : state);
}

export function formAction(state = {}, action = {}) {
  return (action.type === FORM_ACTION ? action.payload : state);
}

export function taskAction(state = {}, action = {}) {
  return (action.type === TASK_ACTION ? action.payload : state);
}

export function formComment(state = {}, action = {}) {
  const { payload = {}, type } = action;
  switch(type) {
      case FORM_COMMENT: {
          const { data, message = null, status } = payload;
          return { ...state, data, message, status };
      }
      case FORM_COMMENT_RESET:
          if(payload.id) { // reset one
              return { ...state, [payload.id]: {} };
          } else { // reset all
              return {};
          }
      default:
          return state;
  }
}

export function formComments(state = {}, action = {}) {
  const { payload = {}, type } = action;
  switch(type) {
      case FORM_COMMENT: {
          const { data: comment, status: commentStatus } = payload;
          const commentsStateData = state.data || []
          if(commentStatus === ACTION_STATUS.SUCCESS) {
              commentsStateData.unshift(comment); // comments are sorted latest first
              return { ...state, data: commentsStateData };
          }
          return state;  
      }      
      case FORM_COMMENTS: {
          const { data = [], status = "" } = payload;
          return { ...state, data, status };
      }
      case FORM_COMMENTS_RESET: {
          if(payload.id) { // reset one
              return { ...state, [payload.id]: {} };
          } else { // reset all
              return {};
          }
      }
      default:
          return state;
  }
}