import { connect } from "react-redux";
import withRouter from "../../Common/hocs/withRouter";
import List from "../components/List";

const mapStateToProps = (state) => {
  const user = (state?.authentication?.user ?? {});
  return {
    user
  };
};

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(List));