import React from "react";
import { Navigate } from "react-router-dom";
import { MuiStyles } from "@osu/react-ui";
import FormDetail from "./FormDetail";
import { formData } from "../../util/enums";

export default function List(props) {
    const data = props?.data || formData;
    const listType = props?.match?.params?.listType;
    const { isStudent = false, isUnderGradStudent = false } = props.user;
    const [expanded, setExpanded] = React.useState(false);
    const theme = MuiStyles.useTheme();

    if(isStudent === false) return (<Navigate to="/unauthorized" />);

    const handleChange = (panel) => (event) => {
        let isExpanded = panel === expanded;
        setExpanded(isExpanded ? false : panel);
    };

    const formContainerOpenSx = { marginBottom: theme.spacing(3), marginTop: theme.spacing(3), transition: "margin 500ms" };
    const formContainerClosedSx = { marginBottom: theme.spacing(1), marginTop: theme.spacing(1), transition: "margin 500ms" };

    return (
        <div>
            {data?.[listType]?.forms.map((form, index)=>{
                const { permitUnderGrad = false } = form;
                if(isUnderGradStudent === true && permitUnderGrad === false ) {
                    return null;
                } else {
                    return (
                        <div key={index} sx={expanded === `panel${index}` ? formContainerOpenSx : formContainerClosedSx}>
                            <FormDetail form={form} expanded={expanded === `panel${index}`} onExpandClick={handleChange(`panel${index}`)}/>
                        </div>
                    );
                }
            })}
        </div>
    );
}