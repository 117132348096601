import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

export default function Report(props = {}) {
  const { Component, match = {} } = props;
  const { pathname = "" } = match;
  
  const navigate = useNavigate();
  const [hasValidated, setValidated] = useState(false);
  const [isValidating, setValidating] = useState(true);

  useEffect(() => {
    if(hasValidated === false && isValidating === true) {
        // disallow create and edit of a report
        if(pathname.includes("/create") || pathname.includes("/edit")) navigate("/");
        setValidated(true);
        setValidating(false);
    } else {
        setValidating(false);
    }
  }, [hasValidated, isValidating, pathname, navigate]);

  return (<Component {...props} isValidating={isValidating} />);
}