import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "form",
    questions: [
      {
        title: "Grad Student OSU ID",
        id: "osuid",
        required: true,
        type: inputMappings.lookup,
        student: true
      },
      {
        title: "Graduation Level",
        id: "graduation-information-level",
        required: true,
        type: inputMappings.dropDown,
        summary: "If you are uncertain of your academic level, please contact your graduate program coordinator.",
        subtype: dropdownSubtypes.graduationLevel,
        branch: "graduation-information-plan",
        dataField: "gradLevel"
      },
      {
        title: "Graduation Plan",
        id: "graduation-information-plan",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.graduatePrograms,
        dataField: "academicPlan",
        filter: [
          {
            field: "graduation-information-level",
            match: "gradLevel",
            operation: "includes"
          }
        ],
        results: [
          {
            dataField: "gradDegree",
            label: "Graduation Degree",
            field: "degree"
          }
        ],
        branch: "specialization",
        dependencyHelperText: "Graduation Level must be selected before choosing a Graduation Plan",
        dependencies: ["graduation-information-level"]
      },
      {
        title: "Specialization",
        id: "specialization",
        required: true,
        type: inputMappings.dropDown,
        dataField: "specialization",
        subtype: dropdownSubtypes.specialization,
        filter: [
          {
            field: "graduation-information-plan",
            match: "academicPlan"
          }
        ]/* ,
        dependencyHelperText: "Please select a plan to select the appropriate specialization.",
        dependencies: ["graduation-information-plan"] */
      },
      {
        id: "advisor-info",
        type: inputMappings.dropDown,
        title: "Advisor",
        subtype: dropdownSubtypes.advisor,
        dataField: "advisor",
        dependencies: ["graduation-information-plan"],
        dependencyHelperText: "Graduation Plan must be selected before choosing an Advisor",
        required: true
      }
    ]
  }
];