import { sortBy } from "lodash";
import ApprovalStatus from "../Common/components/ApprovalStatus";
import { FORM_TYPE } from "../util/constants"; 
import { formatDate } from "../util/functions";

export function transformTasks(tasks = []) {
    return sortBy(tasks.map(task => {
        task.creationTime = formatDate(task.creationTime);
        const formTypeLabel = FORM_TYPE[task.formType];
        task.formTypeLabel = formTypeLabel;
        let description = "";
        if(formTypeLabel) description += `${formTypeLabel}`;
        if(task?.studentName) description += (description.length > 0 ? ` for ${task.studentName}` : `${task.studentName}`);
        if(task?.studentid) description += (description.length > 0 ? ` - ${task.studentid}` : `${task.studentid}`);
        task.description = description;
        const examDate = (task?.examDate ? formatDate(task.examDate, "LL") : "");
        task.examDate = examDate;
        task.status = ((status) => {
            return { Component: () => (<ApprovalStatus value={status} />), value: status }
        })(task?.status);
        return task;
    }), ["creationTime"]);
}