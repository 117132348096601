import { Mui } from "@osu/react-ui";
import React from "react";

function HelperText(props = {}) {
    const msg =  "If your permanent address is incorrect, please update it at ";
    const hyperLink = [<Mui.Link href="https://buckeyelink.osu.edu">buckeyelink.osu.edu</Mui.Link>];
    return (
        <dev>
            {<p style={{ fontSize: '0.95em', color: 'black' }}>{msg}{hyperLink}</p>}
        </dev>
    ); 

}

export default HelperText;