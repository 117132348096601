import { Mui } from "@osu/react-ui";
import { connect } from "react-redux";
import ExternalLink from "../../Common/components/ExternalLink";
import { getStudentInfo } from "../../actions-index";
import { ACTION_STATUS } from "../../util/constants";
import StudentInfo from "../components/StudentInfo";

const mapStateToProps = (state, ownProps) => {
  const osuid = ownProps.osuid ?? state?.authentication?.user?.osuid
  const studentInfoState = state?.studentInformation ?? {}
  const record = studentInfoState?.[osuid]
  let responses = []  
  let heading = "Student Information"

  if(record) {
    if(Object.keys(record?.address || {}).length) {
      const { address, email } = record
      let addressResponses = []
      addressResponses.push({
        value: <Mui.Typography variant="body1" className="bold">
          {record.name}
        </Mui.Typography>
      })
      if(email) {
        addressResponses.push({
          value: (<ExternalLink href={`mailto: ${email}`}>{email}</ExternalLink>)
        });
      }
      if(address.address1) {
        addressResponses.push({
          id: `address1-${osuid}`,
          value: address.address1
        })
      }
      if(address.address2 && !!address.address2.trim()) {
        addressResponses.push({
          id: `address2-${osuid}`,
          value: address.address2
        })
      }
      let line2 = ""
      if(address.city) line2 += address.city
      if(address.state) line2 += `${line2 ? `, ` : ""}${address.state}`
      if(address.postalCode) line2 += `${line2 ? ` ` : ""}${address.postalCode}`
      addressResponses.push({
        id: `citystate-${osuid}`,
        value: line2
      })
      addressResponses.length && responses.push({
        responses: addressResponses
      })
    }
    let secondColumn = [
      {
        value: record.osuid || "-",
        label: 'OSU ID'
      },
      {
        value: record.acadPlan || "-",
        label: 'Academic Plan'
      },
      {
        value: record.admitTerm || "-",
        label: 'Admit Term'
      },
      {
        value: record.degree || "-",
        label: 'Degree'
      }
    ]
    responses.push({
      responses: secondColumn
    })
    let thirdColumn = [
      {
        value: record.withdrawn || "-",
        label: 'Withdrawn'
      },
      {
        value: record.feesPaid || "-",
        label: 'Fees Paid'
      },
      {
        value: record.postCandidacy || "-",
        label: 'Post-candidacy'
      }
    ]
    responses.push({
      responses: thirdColumn
    })
    let fourthColumn = [
      {
        value: record.earnedHours || "-",
        label: 'Earned Hours'
      },
      {
        value: record.cumulativeHours || "-",
        label: 'Cumulative Hours'
      },
      {
        value: record.cumulativeGPA || "-",
        label: 'Cumulative GPA'
      },
      {
        value: record.missingGrades || "-",
        label: 'Missing Grades'
      }
    ]
    responses.push({
      responses: fourthColumn
    })
    if(record?.asOfTerm) {
      heading += ` (as of ${record.asOfTerm})`
    }
  }

  if(studentInfoState?.status === 'loading' && !responses?.length) {
    let skeletons = {
      responses: [...Array(4)].map((item, index) => ({ 
        label: <Mui.Skeleton key={`label-loading-${index}`} width="3rem" />, 
        value: <Mui.Skeleton key={`value-loading-${index}`}  width="7rem" /> 
      }))
    }
    responses = [
      {
        responses: [...Array(4)].map((item, index) => ({ value: <Mui.Skeleton key={`address-val-loading-${index}`} width="10rem" /> }))
      },
      skeletons,
      skeletons,
      skeletons
    ]
  }

  const error = (studentInfoState?.status === ACTION_STATUS.ERROR)
  const loading = (studentInfoState?.status === ACTION_STATUS.LOADING)

  return {
    osuid,
    record,
    responses: responses || [],
    heading,
    loading,
    error: error ? "Could not find student information." : "",
    fetchRecord: !record && !loading && !error,
  };
};

const mapDispatchToProps = (dispatch) => ({
  getStudentInfo: (osuid) => dispatch(getStudentInfo(osuid))
});

export default connect(mapStateToProps, mapDispatchToProps)(StudentInfo);
