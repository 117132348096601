import React from "react";
import { Mui } from "@osu/react-ui";
import ExternalLink from "../Common/components/ExternalLink";
import {
    formTypeIdentifiers, FORM_TYPE, FORM_TYPE_HTML_FRIENDLY, formCategoryIdentifiers, FORM_CATEGORY, GRAD_SCHOOL_HANDBOOK_URL,
    GRAD_SCHOOL_REGISTRATION_SERVICES_EMAIL
} from "./constants";

export function FormsExternalLink(props) {
    const { path = "", text = "" } = props;
    return (<ExternalLink href={`${GRAD_SCHOOL_HANDBOOK_URL}/${path}`}>{text}</ExternalLink>);
}

const CAN_CREATE = true;
const CANNOT_CREATE = false;

const graduationAndExamination = {
    "area": FORM_CATEGORY[formCategoryIdentifiers.GRADUATION_AND_EXAMINATION],
    "forms": [
        {
            "name": FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_CANDIDACY_EXAM],
            "canCreate": CAN_CREATE,
            "description": <>
                This completed form must be submitted by the student and received in the Graduate School, with the form including the 
                approval of the advisor and program, no later than two weeks prior to the proposed date for the oral portion of the candidacy
                examination. <FormsExternalLink path="gsh-section-7-doctoral-degrees#section7.3" text="Section 7.3" /> of the Graduate School
                Handbook pertains to the candidacy examination.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_CERTIFICATE_COMPLETION],
            "canCreate": CAN_CREATE,
            "description": <>
                This completed form must be submitted by the student and received in the Graduate School,
                with the form including the approval of the certificate program, no later than the third Friday of the semester
                in which the certificate program is expected to the completed. This application is valid for this semester
                only. <FormsExternalLink number="8.3" path="gsh-section-8-special-graduate-programs#section8.3" text="Section 8.3" />
                &nbsp;of the Graduate School Handbook pertains to graduate certificate programs.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_FINAL_EXAM],
            "canCreate": CAN_CREATE,
            "description": <>
                This completed form must be submitted by the student and received in the Graduate School, with the form including the approval
                of all committee members, at least two weeks prior to the final oral examination date. Also at least two weeks before the final
                oral examination date, the student must submit to the Graduate School a draft copy of their dissertation for a format review based
                on the Graduate School's formatting guidelines. Upon approval of this form, the Graduate School will assign a Graduate Faculty
                Representative for the defense examination. Not less than one week before the final oral examination, a complete digital copy
                dissertation or DMA document draft must be presented to the Graduate Faculty Representative.&nbsp;
                <FormsExternalLink path="gsh-section-7-doctoral-degrees#section7.9" text="Section 7.9" /> of the Graduate School Handbook
                pertains to the Final Oral Examination.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_FOR_PROFESSIONAL_EXAM],
            "canCreate": CAN_CREATE,
            "description": <>
                This completed form must be submitted by the student and received in the Graduate School, with the form including the approval
                by the advisor and program, no later than two weeks prior to the proposed examination date.&nbsp;
                <FormsExternalLink path="gsh-section-7-doctoral-degrees#section7.17" text="Section 7.17" /> of the Graduate School Handbook
                pertains to the professional examination.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.APPLICATION_TO_GRADUATE],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.APPLICATION_TO_GRADUATE],
            "canCreate": CAN_CREATE,
            "description": <>
                This completed form must be submitted by the student and received in the Graduate School, with the form including the approval
                by the advisor and program, no later than the third Friday of the semester in which graduation is expected. This application is
                valid for this semester only. Sections <FormsExternalLink path="gsh-section-6-masters-degree#section6.5" text="6.5 (masters)" />
                &nbsp;and <FormsExternalLink path="gsh-section-7-doctoral-degrees#section7.12" text="7.12 (doctoral)" /> of the Graduate School
                Handbook pertain to the Application to Graduate.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.COMMITTEE_AND_EXAM_PETITION],
            "canCreate": CAN_CREATE,
            "description": <>
                The Committee and Examination Petition will allow a student to request additions or changes to an examination committee, video
                conferencing of an examination, or to request a waiver of Graduate School policy. All petition requests must first be approved
                by the student's advisor and Graduate Studies Committee Chair in order to be reviewed by the Graduate School.
            </>
        },
         {
            "name": FORM_TYPE[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.DELAY_OF_FINAL_DOCUMENT],
            "canCreate": CAN_CREATE,
            "description": <>
                Submission of this form will allow a student to place an embargo on the dissemination of their thesis or dissertation through
                OhioLINK for a period of not more than five years. This form should be submitted once theGraduate School has approved the
                student's final document submission.
            </>
        }
    ]

};

const enrollment = {
    "area": FORM_CATEGORY[formCategoryIdentifiers.ENROLLMENT],
    "forms": [
        {
            "name": FORM_TYPE[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.COMBINED_DEGREE_PROGRAM],
            "canCreate": CAN_CREATE,
            "permitUnderGrad": true,
            "description": <>
                The purpose of a combined degree program is to give outstanding students an opportunity to simultaneously pursue a graduate and
                undergraduate/professional degrees in different colleges by reducing the amount of time required to complete both sets of
                requirements. Admission to a combined graduate and undergraduate/professional program requires admission to the Graduate School,
                approval from the graduate studies committee chair of the proposed graduate program and an appropriate official of the
                undergraduate, or professional college (e.g., academic advisor and/or dean or director). Combined degree students must meet the
                minimum GPA requirements detailed in Special graduate programs section of the Graduate School Handbook. This form should be
                submitted no later than two weeks prior to start term of the proposed combined program.
            </>
        },
        /* {
            "name": FORM_TYPE[formTypeIdentifiers.DUAL_DEGREE_PROGRAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.DUAL_DEGREE_PROGRAM],
            "canCreate": CAN_CREATE,
            "description": <>
                This form is used to request the simultaneous pursuit of two graduate degrees. The following form is used to detail a plan of
                study between two programs. Students should submit this form (at least) one term prior to dual enrollment. For more information,
                please refer to the Dual Master's Degree Programs section in the Graduate School Handbook.
            </>
        }, */
        {
            "name": FORM_TYPE[formTypeIdentifiers.LATE_COURSE_PETITION],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.LATE_COURSE_PETITION],
            "canCreate": CAN_CREATE,
            "description": <>
                This form should be used if you are requesting to add, drop or audit a course after the published university deadline. This form
                can also be used to request a refund for a course that was dropped after the full refund period (i.e., backdated drop).
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.LEAVE_OF_ABSENCE_FROM_PROGRAM],
            "canCreate": CAN_CREATE,
            "description": <>
                The Pre-Candidacy Leave of Absence Form is to be completed by students wishing to apply for a leave of absence from their
                doctoral program due to extenuating circumstances. The form must be completed in its entirety and receive the necessary approvals
                before going into effect. Please note that approved leaves of absence are valid for up to one academic year. After this time, you
                will need to contact your program to establish expectations for your return. Students are strongly encouraged to work with their
                programs and respective campus units to identify potential impacts associated with leaves of absence, including Student Health
                Insurance and other University provided benefits. Additionally, please note that this form should not be used to request a
                withdrawal from a degree program and should not be used by Graduate Associates (GA) requesting a leave from an appointment. More
                information may be found in the Graduate School Handbook.
            </>
        }, 
        {
            "name": FORM_TYPE[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.MINORS_AND_SPECIALIZATIONS],
            "canCreate": CAN_CREATE,
            "description": <>
                Graduate students interested in obtaining minor or Interdisciplinary Specialization must indicate this intent. This form serves
                as the application to enroll. See <FormsExternalLink path="gsh-section-8-special-graduate-programs#section8.4" text="Section 8.4" />
                &nbsp;in the Graduate School Handbook for general criteria. You may also consult the Graduate School's website for a listing of available
                programs.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.TRANSFER_GRADUATE_CREDIT],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.TRANSFER_GRADUATE_CREDIT],
            "canCreate": CAN_CREATE,
            "description": <>
                If you have attended another university as a graduate student, or attended Ohio State as a graduate non-degree student, and wish
                to apply that credit to your current degree program, please use this form. Please review the policies concerning the transfer of
                credit in the appropriate sections of the Graduate School Handbook. If you require further clarification regarding the transferring
                of credit, please email&nbsp;
                <Mui.Link href={`mailto:${GRAD_SCHOOL_REGISTRATION_SERVICES_EMAIL}`}>{GRAD_SCHOOL_REGISTRATION_SERVICES_EMAIL}</Mui.Link>.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.WITHDRAWAL_FROM_PROGRAM],
            "canCreate": CAN_CREATE,
            "description": <>
                The Withdrawal from Program Form is to be completed by students wishing to withdraw from their degree program. The form must be
                completed in its entirety and receive the necessary approvals before going into effect. Students are strongly encouraged to work
                with their programs and respective campus units to identify potential impacts associated with withdrawals from degree programs,
                including Student Health Insurance and other University provided benefits. Additionally, please note that this form should not be
                used to request a leave of absence from a degree program and should not be used by Graduate Associates (GA) requesting a leave of
                absence from an appointment. More information may be found in the Graduate School Handbook.
            </>
        }
    ]
};

const reports = {
    "area": FORM_CATEGORY[formCategoryIdentifiers.REPORTS],
    "forms": [
        {
            "name": FORM_TYPE[formTypeIdentifiers.REPORT_ON_CANDIDACY],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_CANDIDACY],
            "canCreate": CANNOT_CREATE,
            "description": <>
                This form is created by the Graduate School on the oral date and time of an approved candidacy examination. The results of the
                examination should be recorded by the committee and submitted to the Graduate School immediately after completion of the oral
                examination. <FormsExternalLink path="gsh-section-7-doctoral-degrees#section7.6" text="Section 7.6" /> of the Graduate School
                Handbook pertains to the result of the candidacy examination.
            </>

        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_FINAL_DOCUMENT],
            "canCreate": CANNOT_CREATE,
            "description": <>
                This form is produced by the Graduate School and sent to the student's graduate program along with the Report on Final Examination
                form. The approval of the final thesis/dissertation is recorded on the form by the document committee and submitted back to the
                Graduate School by the posted deadline in the semester in which degree requirements will be completed.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.REPORT_ON_FINAL_EXAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_FINAL_EXAM],
            "canCreate": CANNOT_CREATE,
            "description": <>
                This form is produced by the Graduate School and sent to a student's graduate program prior to the final examination. The results
                of the final examination are recorded on the form by the examination committee and submitted back to the Graduate School by the
                posted deadline in the semester in which degree requirements will be completed.
            </>
        },
        {
            "name": FORM_TYPE[formTypeIdentifiers.REPORT_ON_PROF_EXAM],
            "type": FORM_TYPE_HTML_FRIENDLY[formTypeIdentifiers.REPORT_ON_PROF_EXAM],
            "canCreate": CANNOT_CREATE,
            "description": <>This form is produced by the Graduate School and sent to a student's graduate program prior to the professional examination.
                The results of the professional examination are recorded on the form by the examination committee and submitted back to the Graduate School
                by the posted deadline in the semester in which degree requirements will be completed.</>
        }
    ]
}

export const formDataEnum = {
    "graduation-and-examination": graduationAndExamination,
    "enrollment": enrollment,
    "reports": reports
};