import { connect } from "react-redux";
import { FORM_COMMENT_RESET, FORM_COMMENTS_RESET, resetState, createFormComment, getFormComments } from "../../actions-index";
import formDefinitions from "../../Forms/paths";
import Comments from "../components/Comments";

const mapStateToProps = (state, ownProps) => {
    const id = ownProps?.match?.params?.formId;
    const formTypeFriendly = ownProps?.match?.params?.formType;
    const formType = formDefinitions[formTypeFriendly]?.formType;
    const { authentication = {}, form } = state;
    const emplid = form[id]?.studentid
    const { status: authStatus = "", user = {} } = authentication;
    const formCommentMap = state.formComment;
    const formCommentsMap = state.formComments;
   
    return {
        emplid,
        formType,
        formId: id,
        authStatus,
        formCommentMap,
        formCommentsMap,
        user
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        createFormComment: (emplid, formId, formType, isPrivate, text) => dispatch(createFormComment(emplid, formId, formType, isPrivate, text)),
        getFormComments: (emplid, formId, formType) => dispatch(getFormComments(emplid, formId, formType)),
        resetAllFormComment: () => dispatch(resetState(FORM_COMMENT_RESET, {})),
        resetAllFormComments: () => dispatch(resetState(FORM_COMMENTS_RESET, {})),
        resetFormComment: (id) => dispatch(resetState(FORM_COMMENT_RESET, { id })),
        resetFormComments: (id) => dispatch(resetState(FORM_COMMENTS_RESET, { id })),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Comments)





