import { Mui } from "@osu/react-ui";
import { Fragment } from "react";
import { BOOL } from "../../util/constants";
import { dropdownSubtypes, inputMappings } from "../../util/enums";
import { FormsExternalLink } from "../../util/formDataEnum";

export const formSections = [
  {
    id: "faculty-appt-gs-handbook",
    Component: () => {
      return (
        <Fragment>
          <Mui.Typography variant="body2" className="margin-bottom-1">
            <FormsExternalLink path="gsh-section-12-graduate-faculty-membership" text="Section 12" /> of the Graduate School handbook
            outlines the Graduate Faculty appointments.
          </Mui.Typography>
          <Mui.Typography variant="body2">
            This nomination serves to inform the Graduate School that the program wishes to nominate the faculty member named
            below to the Graduate Faculty in its academic unit and that the education and experience of the faculty member qualify
            him/her to perform the functions appropriate to the category level proposed. 
          </Mui.Typography>
        </Fragment>
      );
    }
  },
  {
    title: "Faculty Member Information",
    id: "faculty-member-info",
    validation: (values) => {
      const facultyMember = (values?.["faculty-lookup"]?.value ?? {});
      const keys = Object.keys(facultyMember).filter(key => (key !== "emplid"));
      return (keys.length > 0 && facultyMember?.facultyNomination === true); // has a key other than emplid
    },
    questions: [
      {
        id: "faculty-lookup",
        type: inputMappings.lookup,
        dataField: "facultyMember",
        required: true,
        facultyNomination: true,
        title: "Faculty Lookup",
        graphql: [
          {
            label: "OSUID",
            dataField: "emplid"
          },
          {
            label: "First Name",
            dataField: "firstName"
          },
          {
            label: "Middle Name",
            dataField: "middleName"
          },
          {
            label: "Last Name",
            dataField: "lastName"
          },
          {
            label: "Faculty Phone",
            dataField: "phone"
          },
          {
            label: "Faculty E-mail",
            dataField: "facultyEmail"
          },
          {
            label: "Faculty Nomination",
            dataField: "facultyNomination",
            hidden: true
          }
        ],
        HelperText: () => (
          <Mui.Typography className="margin-top-3" variant="subtitle2">
            To display faculty Info here enter a valid grad faculty OSU ID in "Faculty OSU ID" field and click "Lookup Faculty".
          </Mui.Typography>
        )
      }
    ]
  },
  {
    title: "Current Academic Information",
    id: "current-academic-info",
    dependsOn: "faculty-member-info",
    questions: [
      {
        id: "faculty-rank",
        title: "Faculty Rank",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.facultyRank,
        dataField: "facultyRank"
      },
      {
        id: "academic-unit",
        title: "Academic Unit",
        summary: "Faculty Member's Home Department",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.academicUnit,
        dataField: "academicPlan"
      },
      {
        id: "highest-degree",
        title: "Highest Degree Obtained",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.degrees,
        dataField: "highestDegree"
      },
      {
        id: "osu-degree",
        title: "Was this degree obtained at the Ohio State University?",
        required: true,
        type: inputMappings.radio,
        dataField: "osuDegree",
        choices: [
          {
            id: "yes",
            value: BOOL.YES,
            title: "Yes",
            alter: {
              sectionId: "current-academic-info",
              questionId: "degree-institution",
              dataField: "degreeInstitution",
              value: "OSU"
            }
          },
          {
            id: "no",
            value: BOOL.NO,
            title: "No",
            alter: {
              sectionId: "current-academic-info",
              questionId: "degree-institution",
              dataField: "degreeInstitution",
              value: ""
            }
          }
        ]
      },
      {
        id: "degree-year",
        type: inputMappings.numberInput,
        required: true,
        title: "Degree Year",
        dataField: "degreeYear",
        inputProps: {
          min: 0
        }
      },
      {
        id: "degree-institution",
        type: inputMappings.textInput,
        required: true,
        title: "Degree Institution",
        dataField: "degreeInstitution"
      }
    ]
  },
  {
    title: "Proposed Academic Information",
    id: "proposed-academic-info",
    dependsOn: "faculty-member-info",
    questions: [
      {
        id: "category",
        title: "Category",
        dataField: "proposedCategory",
        required: true,
        type: inputMappings.radio,
        choices: [
          {
            id: "P",
            value: "P",
            title: "P"
          },
          {
            id: "M",
            value: "M",
            title: "M"
          }
        ]
      },
      {
        id: "grad-plan-admin-programs",
        title: "Program",
        dataField: "proposedGradProgram",
        required: true,
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.gradPlanAdminPrograms
      }
    ]
  },
  {
    title: "Attachments",
    id: "attachments",
    dependsOn: "faculty-member-info",
    questions: [
      {
        id: "file-uploader",
        type: inputMappings.fileUpload,
        Description: () => (
          <Mui.Typography variant="body2" className="margin-bottom-1">
            If this nomination is for Category P status please attach a letter of request from the Graduate Studies Committee and a current CV for the nominee.
          </Mui.Typography>
        ),
        required: false,
        multiple: true,
        dataField: "attachments",
        defaultValue: null,
        maxFileCount: 5,
        maxFileSize: 20000000 // 20MB
      },
      {
        id: "additional-comments",
        dataField: "comments",
        type: inputMappings.textInput,
        title: "Additional Comments",
        multiline: true,
        rows: 5,
        summary: "Information in this request is viewable and subject to disclosure under the Ohio public records statute and rules discovery.",
        required: false,
      }
    ]
  }
];