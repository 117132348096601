import { dropdownSubtypes, inputMappings } from "../../util/enums";

export const formSections = [
  {
    id: "late-course-petition",
    title: "Late Course Petition",
    questions: [
      {
        id: "effective-term",
        title: "Effective Term",
        type: inputMappings.dropDown,
        subtype: dropdownSubtypes.term,
        dataField: 'termCode',
        filter: {
          showFromPresent: 1,
          showHistorical: 4
        }
      },
      {
        id: "advisor-info",
        type: inputMappings.dropDown,
        title: "Advisor",
        dataField: "advisor",
        subtype: dropdownSubtypes.advisor,
        required: false
      },
      {
        id: 'late-course-petition-type',
        type: inputMappings.radio,
        title: 'Please select the option that pertains to your request.',
        dataField: "courseType",
        required: true,
        choices: [
          {
            id: 'add-course',
            value: "ADD",
            title: 'Add Course'
          },
          {
            id: "change-audit-to-grade",
            value: "AUDIT_TO_GRADE",
            title: "Change Audit Course to Grade Course",
          },
          {
            id: "change-grade-to-audit",
            value: "GRADE_TO_AUDIT",
            title: "Change Grade Course to Audit Course",
          },
          {
            id: "drop-course",
            value: "DROP",
            title: "Drop Course",
            branch: "last-date-attend"
          },
      {
        id: "last-date-attend",
        title: "Drop Date Last Attend",
        dataField: 'lastAttendDate',
        type: inputMappings.date,
        required: false,
        dependencies: ["late-course-petition-type"],
      },
        ]
      },
      {
        id: "courses-input-group",
        title: "Courses",
        dataField: 'courses',
        type: inputMappings.inputGroup,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 1,
        questions: [
          {
            id: "courses-ig-number",
            type: inputMappings.textInput,
            title: "Course Number",
            dataField: 'courseNumber',
            summary: null,
            required: true,
          },
          {
            id: "class-number",
            type: inputMappings.textInput,
            title: "Class Number",
            dataField: 'classNumber',
            summary: null,
            required: true,
          },
          {
            id: "subject",
            type: inputMappings.textInput,
            dataField: 'courseSubject',
            title: "Course Subject",
            summary: null,
            required: true,
          },
          {
            id: "credit-hours",
            type: inputMappings.textInput,
            dataField: 'creditHours',
            title: "Credit Hours",
            summary: null,
            required: true,
          },
          {
            id: "instructor-name-n",
            type: inputMappings.textInput,
            dataField: 'instructor',
            title: "Instructor name.#",
            summary: null,
            required: true
          },
          {
            id: "reason",
            type: inputMappings.textInput,
            dataField: 'reason',
            title: "Reason",
            rows: 5,
            summary: null,
            required: true,
            multiline: true,
            gridProps: {
              xs: 12,
              md: 12,
            }
          },
        ]
      },
      {
        id: "committee-approvers",
        type: inputMappings.inputGroup,
        title: "Approvers",
        subtitle: "OSU Employees",
        required: false,
        hidden: true,
        hiddenResponse: true,
        numberOfResponsesMinimum: 1,
        numberOfResponsesMaximum: 5,
        dataField: "committee.approvers",
        deleteAll: true,
        questions: [
          {
            id: "approver-info",
            type: inputMappings.nameNLookup,
            title: "OSU Employee"
          }
        ]
      }
    ],
  },
];

export const gradSchoolPreApprovalQuestions = [
  {
    id: "committee-approvers",
    type: inputMappings.inputGroup,
    title: "Approvers",
    subtitle: "OSU Employees",
    required: true,
    numberOfResponsesMinimum: 1,
    numberOfResponsesMaximum: 5,
    dataField: "committee.approvers",
    deleteAll: true,
    questions: [
      {
        id: "approver-info",
        type: inputMappings.nameNLookup,
        title: "OSU Employee"
      }
    ]
  }
];