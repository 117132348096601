import { Mui } from "@osu/react-ui";
import React from "react";
import HelperText from "./HelperText";

function Address(props = {}) {
  console.log("/Common/components/Person/conponents/Address.js props ", props);
  let {
    id,
    name,
    address1,
    address2,
    city,
    state,
    postalCode,
    country,
    className,
    loading,
    onChange,
    ...rest
  } = props;

  let cityStateZip = "";
  if (city) {
    cityStateZip += city;
  }
  if (state) {
    cityStateZip += `, ${state}`;
  }
  if (postalCode) {
    cityStateZip += `${state ? " " : ", "}${postalCode}`;
  }
  let cls = "display-flex flex-direction-column";
  if (className) cls += ` ${className}`;

  let helperText =  "If your permanent address is incorrect, please update it at";

  let jsx = [];
  let index = 0;
  for (const field of [name, address1, address2, cityStateZip, country]) {
    if (field) {
      jsx.push(
        <Mui.Typography
          component="span"
          variant={index === 0 ? "subtitle2" : "body2"}
        >
          {field}
        </Mui.Typography>
      );
    } else if (loading) {
      jsx.push(
        <Mui.Skeleton
          width={index === 0 ? "5rem" : "10rem"}
          key={`address-${field}-${index}`}
        />
      );
    }

    index++;
  }

  return (
    [<Mui.Card id={id} className={cls} variant="outlined" {...rest}>
      {jsx}   
    </Mui.Card>,
    <HelperText/>]
  );
}

Address.defaultProps = {};

export default Address;
